// This script hides the Filter and Clear buttons on the Doctors#index view
// The submitWhenChecked function is not working in webpacker

$(document).ready(function() {
    $(doctorStatusScripts.setup);
});

var doctorStatusScripts = {
  setup: function() {
    doctorStatusScripts.hideSubmitButtons();
  },

  hideSubmitButtons: function() {
    $('#filter-button').hide();
    $('#clear-button').hide();
  }
};

